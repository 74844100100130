import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import NSPLogo from "../assets/nsp-logo-2.svg";
const Sidebar = styled.div` 
  width: 250px;
  background: linear-gradient(183.93deg, #fff -9.02%, #ededed 111.06%);
  top: 0; 
`;

const Logo = styled.h2`
  color: white;
  margin-bottom: 50px;
  margin-left: 30px;
`;

const MenuItem = styled.div<{ active?: boolean }>`
  color: ${(props: any) => (props.active ? "#000000" : "#000000")};
  background: ${(props: any) => (props.active ? "#ededed" : "transparent")};
  padding: 10px 15px;
  margin: 10px 0;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px; 
  cursor: pointer;

  &:hover {
    background:#A9A9A9;
    color: #00000;
  }
`;

const Logout = styled.div`
  margin-top: 20px;
  color: white;
  cursor: pointer;
`;

interface SidebarComponentProps {
  activeMenuItem?: string;
}
const SidebarComponent: React.FC<SidebarComponentProps> = ({}) => {
  const location = useLocation();
  const activeMenuItem = location.pathname.split("/")[1];
  const navigate = useNavigate();
  return (
    <div >
    <Sidebar
      style={{
        top: "-20px",
        height: "100vh",
        width: "119%",
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "24px",
        position: "absolute",
      }}
    >
      <Logo
        style={{
            height: "20%",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "1rem 1rem 0 0",
        }}
      >
        <img
          src={NSPLogo}
          alt="Logo"
          style={{ width: "140px", height: "100px" }}
        />
      </Logo>
      <div style={{ padding: "20px 0 20px 20px" }}>
        <MenuItem active={activeMenuItem === "dashboard"}onClick={()=>{
          window.location.pathname !== "/dashboard" && navigate("/dashboard")
        }}>Dashboard</MenuItem>
        {/* <MenuItem
          active={activeMenuItem === "registration"}
          onClick={() => {
            window.location.pathname !== "/registration" &&
              navigate("/registration");
          }}
        >
          Registration
        </MenuItem>
        <MenuItem
          active={activeMenuItem === "attendance"}
          onClick={() => {
            window.location.pathname !== "/attendance" &&
              navigate("/attendance");
          }}
        >
          Attendance
        </MenuItem>
        <MenuItem active={activeMenuItem === "employeedetail"} 
        onClick={() => {
          window.location.pathname !== "/employeedetail" &&
            navigate("/employeedetail");
        }}
        >Employee Detail</MenuItem> */}
      </div>
      {/* <Logout>Logout</Logout> */}
    </Sidebar>
    </div>
  );
};

export default SidebarComponent;
