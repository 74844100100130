import React, { useEffect, useState } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";
import SidebarComponent from "../../components/Sidebar";
import styled from "styled-components";
// import { error } from "console";
import {
  Container,
  Row,
  Col,
  Card as BootstrapCard,
  Pagination,
  Button,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { blob } from "stream/consumers";
import { Get } from "../../utils/apiService";
import {IGetVisitResponse, GetUserResponse, GetVisitData} from "../../services/dashboardService"

interface Visitor {
  visitId: string;
  visitor: {
    id: number;
    name: string;
    email: string;
    phone: number;
    // photo: string;
    // aadhar: number;
  };
}

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  margin-left: 200px;
`;
const StyledDateInput = styled.input`
  width: 100%;
  padding: 10px 15px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
  transition: all 0.3s ease-in-out;

  &:focus {
    border-color: #007bff; /* Blue border on focus */
    background-color: #ffffff;
    outline: none;
  }

  &:hover {
    border-color: #0056b3; /* Darker blue on hover */
  }
`;


const Card = styled.div`
  background: #fff;
  padding: 20px;
  padding-right: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  width: 50%;
  margin-right: 0;
`;
const SidebarWrapper = styled.div`
  width: 200px; /* Fixed width for sidebar */
  position: fixed; /* Fix the sidebar to the left */
  top: 0;
  bottom: 0;
  left: -1%;
  background-color: #f8f8f8; /* Sidebar background color */
  padding-top: 20px;
`;

const SectionTitle = styled.h2`
  margin: 20px 0;
  fontfamily: "Poppins, sans-serif";
`;
const VisitorTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background: #f5f5f5;
  }
`;
const EmployeeStatus = styled.span<{ status: string }>`
  color: ${(props) => (props.status === "Present" ? "green" : "red")};
`;

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [visitorData, setVisitorData] = useState<Visitor[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState<Date | null>(null); // For "from" date
  const [toDate, setToDate] = useState<Date | null>(null); // For "to" date
  const [selectedDates, setSelectedDates] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: null,
    to: null,
  });

 

  const fetchVisitorData = async (from: string, to: string) => {
    try {
      const response: IGetVisitResponse = await GetUserResponse(from, to); 
  
      if (response.status === "success" && Array.isArray(response.data)) {
        const visitors:any = response.data.map((visit) => ({
          visitId: visit.visitId,
          visitor: visit.visitor,
        }));
        setVisitorData(visitors); 
      } else {
        setVisitorData([]); 
      }
    } catch (error) {
      console.error("Error fetching visitor data:", error);
      setVisitorData([]); 
    }
  };

  const getLast24Hours = () => {
    const now = new Date();
    const fromDate = new Date(now);
    fromDate.setHours(now.getHours() - 24); // 24 hours ago

    const formatDate = (date: Date) => {
      return date.toISOString().split("T")[0]; 
    };

    return { from: formatDate(fromDate), to: formatDate(now) };
  };
  useEffect(() => {
   const {from, to}= getLast24Hours();
   setFromDate(new Date(from));
   setToDate(new Date(to));
   fetchVisitorData(from, to);
  }, []); 

  const handleSubmit = async () => {
    let dateRange = {from: '', to: ''};
    if (fromDate && toDate) {
      const formatDate = (date: Date) => {
        return date.toLocaleDateString('en-CA');
      };
      dateRange = {
        from: formatDate(fromDate),
        to: formatDate(toDate),
      };
    } else {
      dateRange = getLast24Hours();
    }
  
    try {
      const responseData = await GetVisitData(dateRange.from, dateRange.to);
  
      if (responseData.status === "success" && Array.isArray(responseData.data)) {
        setVisitorData(responseData.data);
      } else {
        setVisitorData([]);
      }
    } catch (error) {
      console.error("Error fetching visitor data:", error);
      setVisitorData([]);
    }
  };
  

  const isActive = (path: string) => location.pathname === path;

  const indexOfLastVisitor = currentPage * itemsPerPage;
  const indexOfFirstVisitor = indexOfLastVisitor - itemsPerPage;
  const currentVisitor = visitorData.slice(
    indexOfFirstVisitor,
    indexOfLastVisitor
  );

  const downloadCSV = () => {
    const headers = [
      "Vistor ID",
      "Visitor Name",
      "Visitor Email",
      "Phone Number",
    ];

    const rows = visitorData.map((visitor) => [
      visitor.visitId,
      visitor.visitor.name,
      visitor.visitor.email,
      visitor.visitor.phone,
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "visitor_data.csv");
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

 
  return (
    <Container fluid>
      <SidebarWrapper>
        {" "}
        <SidebarComponent />
      </SidebarWrapper>
      <MainContent>
        <SectionTitle
          style={{ marginLeft: "2%", fontFamily: "Poppins, sans-serif" }}
        >
          Visitor's List{" "}
        
        </SectionTitle>
        <Row style={{ marginBottom: "20px", marginLeft: "2%" }}>
          <Col  style={{paddingLeft:"25%"}}>
            <DatePicker
              selected={fromDate}
              onChange={(date: Date | null) => setFromDate(date)}
              placeholderText="From Date"
              dateFormat="yyyy/MM/dd"
              customInput={<StyledDateInput />}
            />
          </Col>
          <Col>
            <DatePicker
              selected={toDate}
              onChange={(date: Date | null) => setToDate(date)}
              placeholderText="To Date"
              dateFormat="yyyy/MM/dd"
              minDate={fromDate ? fromDate : undefined}
              customInput={<StyledDateInput />}
            />
          </Col >
          <Col xs={4}>
          <Button
            onClick={handleSubmit}
            variant="primary"
            style={{ marginLeft: "8%", width: "auto" }}
          >
            Submit Dates
          </Button>
          <button type="button" className="btn btn-success"
            onClick={downloadCSV}
            style={{ cursor: "pointer", marginLeft: "8%", }}
          >Download in CSV <i className="bi bi-download"></i></button>
          </Col>
        </Row>

        <BootstrapCard>
  <VisitorTable>
    <thead>
      <tr>
        <th>Visitor ID</th>
        <th>Visitor Name</th>
        <th>Visitor Email</th>
        <th>Phone Number</th>
      </tr>
    </thead>
    <tbody>
      {visitorData.length === 0 ? (
        <tr>
          <td colSpan={4} style={{ textAlign: "center" }}>
            No Visitors Found
          </td>
        </tr>
      ) : (
        currentVisitor.map((visitor) => (
          <tr key={visitor.visitId}>
            <td>{visitor.visitId}</td>
            <td>{visitor.visitor.name}</td>
            <td>{visitor.visitor.email}</td>
            <td>{visitor.visitor.phone}</td>
          </tr>
        ))
      )}
    </tbody>
  </VisitorTable>
</BootstrapCard>
      </MainContent>
    </Container>
  );
};

export default Dashboard;
