import { type AxiosResponse } from "axios";
import { Post } from "../utils/apiService";

const visitorRegistrationEndpoint = "/visitor/register";

export interface IVisitorDetails {
  name: string;
  phone: string;
  email: string;
  visitPurpose: string;
  hostEmail: string;
  aadhar?: string;
  photo: string;
}

export const  registerNewVisitor = async (
  data: IVisitorDetails
): Promise<AxiosResponse<any, IVisitorDetails>> =>
  await Post<any, IVisitorDetails>(`${visitorRegistrationEndpoint}`, data);
