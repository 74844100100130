import { Get } from "../utils/apiService";

interface IVisits {
  name: string;
  email: string;
  phone: string;
}

interface IVisitsData {
  visitId: string;
  visitor: IVisits;
}

export interface IGetVisitResponse {
  status: string;
  statusText: string;
  data: IVisitsData[];
}
export interface IGetVisitDataResponse {
    status: string;
    statusText: string;
    data: any[]; 
  }

export const GetUserResponse = async (from: string, to: string): Promise<IGetVisitResponse> => {
    try {
      const response = await Get<IGetVisitResponse, undefined>(
        `/visits?from=${from}&to=${to}`
      );
      return response;
    } catch (error) {
      console.error("Error fetching visit data:", error);
      throw error;
    } };
    export const GetVisitData = async (from: string, to: string): Promise<IGetVisitDataResponse> => {
        try {
          const queryString = new URLSearchParams({ from, to }).toString();
          const response = await fetch(`http://10.140.242.62:1884/visits?${queryString}`, {
            method: 'GET',
          });
      
          const responseData = await response.json();
          
          return responseData;
        } catch (error) {
          console.error('Error fetching visit data:', error);
          throw error;
        }
      };
